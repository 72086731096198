import React from "react";
import { Post } from "../types";
import { semanticDate } from "../helpers/datefns";
import { Typography, Box } from "@material-ui/core";
import usePostCounter from "../store/usePostCounter";
import useUser from "../store/useUser";
export default function BoardPostSub2({ post: p }: { post: Post }) {
  const [counter] = usePostCounter(p.id);
  const [user] = useUser({ id: p.created_by });
  const { count_comment = 0, count_like = 0 } = counter || {};
  const isVote = p.type === "vote";
  return (
    <Box color="grey.600">
      <Typography variant="body2">
        <Box component="span" mr={1}>
          {user?.name || "익명"}
        </Box>
        <Box component="span" mr={1}>
          {semanticDate(p.created_at)}
        </Box>
        <Box component="span" mr={1}>
          댓글 {count_comment}
        </Box>
        <Box component="span" mr={1}>
          {isVote ? `참여자 ${count_like}명` : `공감 ${count_like}`}
        </Box>
      </Typography>
    </Box>
  );
}
