export const policyFair = {
  apiKey: "AIzaSyAPaJ3OGvqVEVoTFFfU9ON4SKcfQdq4t5w",
  authDomain: "policy-fair-mix.firebaseapp.com",
  databaseURL: "https://policy-fair-mix.firebaseio.com",
  projectId: "policy-fair-mix",
  storageBucket: "policy-fair-mix.appspot.com",
  messagingSenderId: "636422424475",
  appId: "1:636422424475:web:73b245e758dc628a14c33e",
  measurementId: "G-ZD6NW82J00",
};
