import React from "react";
import { firestore } from "../config/firebase";
import { User } from "../types";
export default function useUser({
  id,
  listen = false,
}: {
  id: string;
  listen?: boolean;
}): [User | null] {
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    if (id) {
      if (listen) {
        firestore
          .collection("users")
          .doc(id)
          .onSnapshot((doc) => {
            const item = { id: doc.id, ...doc.data() } as User;
            setUser(item);
          });
      } else {
        firestore
          .collection("users")
          .doc(id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const name = doc.get("name") || "익명";
              const item = { id: doc.id, ...doc.data(), name } as User;
              setUser(item);
            } else {
              const item = { id: doc.id, name: "삭제된 유저" } as User;
              setUser(item);
            }
          });
      }
    }
  }, [id, listen]);
  return [user];
}
