import { Role, UserStatus } from "../types";
export const postSortOptions = [
  { created_at: "desc" },
  { updated_at: "desc" },
  { last_commented_at: "desc_nulls_last" },
];
export const voteOptions = [
  { label: "7일 후 종료", value: "7days" },
  { label: "30일 후 종료", value: "30days" },
  { label: "토론 정리시 종료", value: "manual" },
];

export const suggestionOptions = [
  { label: "7일 후 종료", value: "7days" },
  { label: "30일 후 종료", value: "30days" },
  { label: "토론 정리시 종료", value: "manual" },
];

export const userGroupStatusList = [
  { label: "운영자", value: "organizer" },
  { label: "멤버", value: "user" },
  { label: "참가자", value: "participant" },
  { label: "탈퇴", value: "exit" },
  { label: "승인대기", value: "requested" },
];

export const boardPermissionList = [
  { label: "오거나이저", value: "organizer" },
  { label: "멤버", value: "member" },
  { label: "유저", value: "user" },
  { label: "익명", value: "anonymous" },
];

export function permissionLabelByValue(value: Role) {
  const item = boardPermissionList.find((p) => p.value === value);
  if (item) {
    return item.label;
  }
  return null;
}
export function showStatusLabelByValue(value: UserStatus) {
  const userStatus = userGroupStatusList.find((ug) => ug.value === value);
  if (userStatus) {
    return userStatus.label;
  }
  switch (value) {
    case "requested":
      return "승인대기";
    default:
      return null;
  }
}
export const CUTTING_INDEX = 6;
export const KEYWORD_INDEX = 23;
export const DEFAULT_HASHTAGS = [
  "주민자치",
  "지역_활성화",
  "학습_공동체",
  "주민조직_네트워크",
  "제도_정책",
  "특별공모",
  "서울",
  "부산",
  "대구",
  "인천",
  "광주",
  "대전",
  "울산",
  "세종",
  "경기도",
  "충청남도",
  "충청북도",
  "전라북도",
  "전라남도",
  "경상남도",
  "경상북도",
  "제주",
  "강원도",
  "공모사업",
  "공유공간",
  "공유사업활동",
  "기후위기대응",
  "네트워크활성화",
  "도시재생연계",
  "돌봄활동",
  "마을계획",
  "마을공간",
  "마을기금",
  "마을미디어",
  "마을배움터",
  "마을안전망",
  "마을총회_주민총회",
  "마을여행_마을투어",
  "문화콘텐츠",
  "민간위탁",
  "민관협력",
  "민관협치",
  "숙의공론",
  "안전마을",
  "온라인활동",
  "읍면동장개방직",
  "일자리만들기",
  "자치기금_지역발전기금",
  "재난대응활동_체계",
  "주민세환원",
  "주민자치위원회",
  "주민자치회",
  "주민참여예산연계",
  "주민총회_마을총회",
  "주민협의체",
  "중간지원조직연계",
  "지역경제 활성화",
  "지역네트워크",
  "지역복지",
  "지역사회보장협의체 연계",
  "청년주민자치위원",
  "청년활동",
  "청소년주민자치위원",
  "친환경활동",
  "청소년활동",
  "코로나19대응",
  "평생학습",
  "협동조합_마을기업",
];
export const PARAM_COLLECTION = "$PARAMS$";
export const COUNTER_DOC = "counters";
export const COUNTER_VIEW_POST = "COUNTER_VIEW_POST";
export const HASHTAG_SPLIT_REGEX = /[\s,;#]+/;
